/* eslint-disable no-continue */
/* eslint-disable no-restricted-syntax */
/* eslint-disable default-case */
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import PageView from '../../../containers/PageView';

import { LoadingCustomModal } from '../../../components/Modals/LoadingCustomModal';

import { DropzoneCustom } from '../../../components/DropzoneCustom';
import { DefaultExcelRenderer } from '../../../components/ExcelRenderer';
import GradientButton from '../../../components/GradientButton';
import getUrlsFromS3Responses from '../../../helpers/getUrlsFromS3Responses';
import { getPayloadSize } from '../../../helpers/requestMananagement';
import firstContactRegisterService from '../../../services/attachments/firstContactRegister.service';
import generateInvoicesService from '../../../services/attachments/generateInvoices.service';
import {
  adjustmentExcelDate,
  desiredColumnsBalanceLight,
  desiredColumnsBalanceLightMap,
  desiredColumnsVA,
  validationDate,
} from './helpers';
import { Header, ParagraphAndProviders, Radiobox } from './styles';

const MAX_PAYLOAD_SIZE = import.meta.env.REACT_APP_MAX_PAYLOAD_SIZE_IN_MB;

export const BillingManual = () => {
  const [selected, setSelected] = useState({
    macroProvider: null,
    option: null,
    text: null,
    batch: null,
  });

  const [pageState] = useState({
    loading: false,
    hasError: false,
  });
  const [isSending, setIsSending] = useState(false);
  const navigate = useNavigate();
  const [avaliableOptionsPerMacroProvider] = useState({
    cpfl: [
      { option: 'preparedAttachment', batch: true },
      { option: 'unpreparedAttachment', batch: false },
    ],
    light: [
      { option: 'preparedAttachment', batch: true },
      { option: 'sheet', batch: true },
    ],
    cemig: [
      { option: 'preparedAttachment', batch: true },
      { option: 'sheet', batch: false },
    ],
    enel_sp: [{ option: 'preparedAttachment', batch: true }],
    enel_rj: [{ option: 'preparedAttachment', batch: true }],
    ess: [{ option: 'preparedAttachment', batch: true }],
    elektro: [
      {
        option: 'preparedAttachment',
        batch: true,
      },
      { option: 'unpreparedAttachment', batch: false },
    ],
  });

  const [sheetDataCemig, setSheetDataCemig] = useState({
    unhandledBalance: [],
  });
  const [sheetDataLight, setSheetDataLight] = useState({
    unhandledBalance: [],
  });

  const [sheetFiles, setSheetFiles] = useState([]);

  const [hasResetFiles, setHasResetFiles] = useState(false);

  const { loading, hasError } = pageState;

  const setHelperText = () => {
    const { option: selectedOption } = selected;
    let text;

    switch (selectedOption) {
      case 'unpreparedAttachment':
        text = 'Inclua todos os arquivos com as contas unificadas em PDF.';
        break;
      case 'sheet':
        text = `Inclua o excel disponiblizado no site da ${selected.macroProvider}.`;
    }

    return text ? <HelperText>{text}</HelperText> : null;
  };
  const getOption = (optionsPerMacroProvider) => {
    const { option, batch } = optionsPerMacroProvider;

    const { option: selectedOption } = selected;

    switch (option) {
      case 'preparedAttachment':
        return (
          <label>
            <input
              type='radio'
              name='option'
              onChange={() =>
                setSelected({
                  ...selected,
                  option: 'preparedAttachment',
                  batch,
                })
              }
              checked={selectedOption === 'preparedAttachment'}
            />
            PDF Único
          </label>
        );

      case 'unpreparedAttachment':
        return (
          <label>
            <input
              type='radio'
              name='option'
              onChange={() =>
                setSelected({
                  ...selected,
                  option: 'unpreparedAttachment',
                  batch,
                })
              }
              checked={selectedOption === 'unpreparedAttachment'}
            />
            {macroProvider === 'cpfl'
              ? 'PDF Consolidado'
              : 'Relatório de créditos de energia'}
          </label>
        );
      case 'sheet':
        return (
          <label>
            <input
              type='radio'
              name='option'
              onChange={() =>
                setSelected({ ...selected, option: 'sheet', batch })
              }
              checked={selectedOption === 'sheet'}
            />
            Planilha
          </label>
        );
    }
  };

  const setOptionsPerMacroProvider = () => {
    const { macroProvider } = selected;
    const options = avaliableOptionsPerMacroProvider[macroProvider];
    if (!macroProvider) return;
    return (
      <>
        <Radiobox>
          <p>Selecione o modo:</p>
          {options.map(getOption)}
        </Radiobox>
        {setHelperText()}
      </>
    );
  };
  const handleRequest = async (payload) => {
    const { option: selectedOption, macroProvider } = selected;
    switch (selectedOption) {
      case 'preparedAttachment': {
        const formData = new FormData();
        formData.append('macroProviderCode', macroProvider);
        payload.forEach((attachment) => formData.append('file', attachment));
        payload.forEach(async (attachment) => {
          const unitFormData = new FormData();
          unitFormData.append('macroProviderCode', macroProvider);
          unitFormData.append('file', attachment);
          await generateInvoicesService.uploadPdf(unitFormData);
        });
        await generateInvoicesService.trigggerPreProcess(formData);
        break;
      }
      case 'unpreparedAttachment': {
        const time = Date.now();
        const responses = await Promise.all(
          payload.map((attachment) =>
            generateInvoicesService.uploadUnpreparedAttachment(attachment, time)
          )
        );
        const urls = getUrlsFromS3Responses(responses);
        await firstContactRegisterService.createUnpreparedAttachmentsRegister(
          urls,
          macroProvider
        );
        await generateInvoicesService.startBatchJob(
          time,
          payload,
          macroProvider
        );
        toast.success(
          'Recebemos o arquivo. A emissão de faturas Flora foi iniciada.'
        );
        break;
      }
      case 'sheet': {
        const time = Date.now();
        const responses = await Promise.all(
          sheetFiles.map((attachment) =>
            generateInvoicesService.uploadSheetAttachment(attachment, time)
          )
        );
        const urls = getUrlsFromS3Responses(responses);
        await firstContactRegisterService.createSheetRegisters(
          urls,
          macroProvider
        );

        if (macroProvider === 'cemig') {
          const { balance } = payload;
          const url = await generateInvoicesService.uploadJsonAttachment(
            balance,
            time
          );

          await generateInvoicesService.createOcrsFromSheet(url, macroProvider);
          toast.success(
            'Recebemos o arquivo. A emissão de faturas Flora foi iniciada.'
          );
          return;
        }
        await generateInvoicesService.createOcrsFromSheet(
          payload,
          macroProvider
        );
        break;
      }
    }
  };

  const createAttachmentsPayloads = (files) => {
    const maxSizePayload = MAX_PAYLOAD_SIZE * 1024 * 1024;
    const payloadFiles = [];
    let currentPayload = [];
    let currentPayloadSize = 0;

    for (const file of files) {
      if (currentPayloadSize + file.size > maxSizePayload) {
        payloadFiles.push(currentPayload);
        currentPayload = [];
        currentPayloadSize = 0;
      }

      if (file.size <= maxSizePayload) {
        currentPayload.push(file);
        currentPayloadSize += file.size;
      }
    }

    if (currentPayload.length > 0) {
      payloadFiles.push(currentPayload);
    }

    return payloadFiles;
  };

  const createSheetPayloads = (sheet) => {
    const maxSizePayload = MAX_PAYLOAD_SIZE * 1024 * 1024 * 0.5;
    const payload = [];
    let currentPayload = [];
    let currentPayloadSize = 0;

    for (const raw of sheet) {
      const rawSize = getPayloadSize(raw);

      if (currentPayloadSize + rawSize > maxSizePayload) {
        payload.push(currentPayload);
        currentPayload = [];
        currentPayloadSize = 0;
      }

      if (rawSize <= maxSizePayload) {
        currentPayload.push(raw);
        currentPayloadSize += rawSize;
      }
    }

    if (currentPayload.length > 0) {
      payload.push({ balance: currentPayload });
    }

    return payload;
  };

  const getPreparedPayloads = (payload) => {
    const { option: selectedOption, macroProvider, batch } = selected;
    if (!batch) return payload;
    switch (selectedOption) {
      case 'preparedAttachment':
        return createAttachmentsPayloads(payload);
      case 'sheet':
        if (macroProvider === 'light') {
          const { balance } = payload;
          return createSheetPayloads(balance);
        }
        throw new Error(
          `create getPreparedPayload for macroProvider ${macroProvider} `
        );
    }
  };

  const typeCheck = (preparedPayloads) => {
    const { option: selectedOption, macroProvider, batch } = selected;

    const errorMessage = `create getPreparedPayload for macroProvider ${macroProvider} and option ${selectedOption}, should be an array`;
    if (typeof preparedPayloads !== 'object') throw new Error(errorMessage);
    if (selectedOption === 'sheet' && macroProvider === 'cemig') {
      if (Array.isArray(preparedPayloads)) throw new Error(errorMessage);
    } else {
      if (!Array.isArray(preparedPayloads)) throw new Error(errorMessage);
    }
  };

  const handleRequests = async (payload) => {
    const { batch } = selected;
    const preparedPayloads = getPreparedPayloads(payload);

    typeCheck(preparedPayloads);

    if (!batch) return handleRequest(preparedPayloads);

    const promises = preparedPayloads.map(handleRequest);

    return Promise.allSettled(promises).then((results) => {
      const totalSuccess = results.filter(
        (r) => r.status === 'fulfilled'
      ).length;
      if (totalSuccess)
        toast.success(
          `${totalSuccess}/${results.length} sucessos no batch de upload.`
        );
      const totalErros = results.filter((r) => r.status === 'rejected').length;
      if (totalErros)
        toast.error(
          `${totalErros}/${results.length} erros no batch de upload.`
        );
    });
  };

  const handleSubmit = async (payload) => {
    const { macroProvider, option } = selected;
    if (!payload.length && option !== 'sheet') return;
    if (sheetFiles?.length < 1 && option === 'sheet') return;
    if (!macroProvider.length) return toast.error('Selecione a distribuidora');

    try {
      setIsSending(true);

      await handleRequests(payload);
    } catch (err) {
      console.log(err);
    } finally {
      setIsSending(false);
    }
  };

  const handleSelectMacroProvider = useCallback(({ target: { value } }) => {
    setSelected({ ...selected, macroProvider: value });
  }, []);

  useEffect(() => {
    setHasResetFiles(true);
    setSelected({ ...selected, option: null, text: null });
    setSheetFiles([]);
  }, [selected.macroProvider]);
  useEffect(() => {
    setHasResetFiles(true);
  }, [selected.option]);

  useEffect(() => {
    try {
      const { unhandledBalance } = sheetDataCemig;
      if (!unhandledBalance.length) return;

      const balance = [];

      for (const unhandledItem of unhandledBalance) {
        const item = {};
        for (const key in unhandledItem) {
          if (desiredColumnsVA.includes(key)) {
            item[key] = unhandledItem[key];
          }
        }

        balance.push(item);
      }
      setSheetDataCemig((prev) => ({ ...prev, balance }));
    } catch (error) {
      console.log('file: index.jsx:390 || error:', error);
      toast.error(error?.message);
      setSheetDataCemig({ unhandledBalance: [] });
    }
  }, [sheetDataCemig.unhandledBalance]);

  useEffect(() => {
    try {
      const { unhandledBalance } = sheetDataLight;
      if (!unhandledBalance?.length) return;

      const unhandledBalanceFiltered = unhandledBalance.filter(
        (item) => item['Posto Horário (desc.)'] === 'Fora Ponta/Geral'
      );

      const balance = [];

      for (const itemFiltered of unhandledBalanceFiltered) {
        const item = {};
        for (const key in itemFiltered) {
          if (desiredColumnsBalanceLightMap.hasOwnProperty(key)) {
            const newKey = desiredColumnsBalanceLightMap[key];
            item[newKey] = itemFiltered[key];
          }
        }

        balance.push(item);
      }
      setSheetDataLight({ ...sheetDataLight, balance });
    } catch (error) {
      console.log('🚀 ~ file: index.jsx:356 ~ useEffect ~ error:', error);
      toast.error(error?.message);
      setSheetDataLight({ unhandledBalance: [], balance: [] });
    }
  }, [sheetDataLight.unhandledBalance]);

  const { macroProvider, option } = selected;

  return (
    <PageView
      title='Faturamento Manual'
      loading={loading}
      hasError={hasError}
      navigate={navigate}
    >
      <LoadingCustomModal isLoading={isSending} />
      <Header>
        <ParagraphAndProviders>
          <p>Upload de contas de luz, para a execução de faturamento.</p>

          <Radiobox>
            <p>Selecione a distribuidora:</p>

            {Object.keys(avaliableOptionsPerMacroProvider).map(
              (availableMacroProvider) => (
                <label>
                  <input
                    type='radio'
                    name='radio'
                    value={availableMacroProvider}
                    onChange={handleSelectMacroProvider}
                    checked={macroProvider === availableMacroProvider}
                  />
                  {availableMacroProvider.toUpperCase().replace('_', ' ')}
                </label>
              )
            )}
          </Radiobox>
          {setOptionsPerMacroProvider()}
        </ParagraphAndProviders>
      </Header>

      {option === 'sheet' && selected.macroProvider === 'cemig' && (
        <>
          <ButtonContainer>
            <GradientButton
              handleClick={() => handleSubmit(sheetDataCemig)}
              variant='outlined'
              text='Enviar'
              disabled={isSending}
            />
          </ButtonContainer>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <p>
              Planilha Agência Virtual:
              <DefaultExcelRenderer
                desiredColumns={desiredColumnsVA}
                isFinalDataObject
                setFile={(file) => {
                  setSheetFiles([...sheetFiles, file]);
                }}
                setAllData={(newData) =>
                  setSheetDataCemig({ unhandledBalance: newData })
                }
                handlerFileAfterChange={true}
                noMaxSize={true}
              />
            </p>
          </div>
        </>
      )}

      {option === 'sheet' && selected.macroProvider === 'light' && (
        <>
          <ButtonContainer>
            <GradientButton
              handleClick={() => handleSubmit(sheetDataLight)}
              variant='outlined'
              text='Enviar'
              disabled={isSending}
            />
          </ButtonContainer>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <p>
              Planilha Saldo de créditos:
              <DefaultExcelRenderer
                desiredColumns={desiredColumnsBalanceLight}
                isFinalDataObject
                setFile={(file) => {
                  setSheetFiles([...sheetFiles, file]);
                }}
                setAllData={(newData) =>
                  setSheetDataLight({
                    unhandledBalance: newData,
                  })
                }
                handlerFileAfterChange={true}
                startRow={1}
                noMaxSize={true}
              />
            </p>
          </div>
        </>
      )}

      {option && option !== 'sheet' && (
        <DropzoneCustom
          maxFiles={50}
          acceptedFileTypes={['.pdf']}
          fileHandler={handleSubmit}
          disabled={isSending}
          hasResetFiles={hasResetFiles}
          isButtonInTop={true}
          noMaxSize={selected?.option === 'unpreparedAttachment'}
        />
      )}
    </PageView>
  );
};

const ButtonContainer = styled.div`
  width: 988px;
  max-width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-bottom: 30px;
`;
const HelperText = styled.div`
  background: #fff1c5;
  color: #5e4646;
  padding: 5px 10px;
  border-radius: 5px;
  width: max-content;
`;
